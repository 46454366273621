import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import AboutPage from './components/Pages/About/About.js';
import Merch from './components/Pages/Merch/merch.js';
import LoginForm from './components/Pages/Login/Login';
import Dashboard from './components/Pages/Dashboard/Dashboard';
import AddTeamRW from './components/Pages/Dashboard/AddTeam/AddTeamRW';
import ViewTeamDetailsRW from './components/Pages/Dashboard/ViewTeam/ViewTeamDetailsRW';
import SubmissionRW from './components/Pages/Dashboard/Submission/SubmissionRW';
import AddTeamAW from './components/Pages/Dashboard/AddTeam/AddTeamAW';
import ViewTeamDetailsAW from './components/Pages/Dashboard/ViewTeam/ViewTeamDetailsAW';
import SubmissionAW from './components/Pages/Dashboard/Submission/SubmissionAW';
import AddTeamES from './components/Pages/Dashboard/AddTeam/AddTeamES';
import ViewTeamDetailsES from './components/Pages/Dashboard/ViewTeam/ViewTeamDetailsES';
import SubmissionES from './components/Pages/Dashboard/Submission/SubmissionES';

import AddTeamTT from './components/Pages/Dashboard/AddTeam/AddTeamTT';
import ViewTeamDetailsTT from './components/Pages/Dashboard/ViewTeam/ViewTeamDetailsTT';
import SubmissionTT from './components/Pages/Dashboard/Submission/SubmissionTT';

import CaHome from './components/Pages/CampusAmbassdor/CA-Home/CAHOME'
import CALoginForm from './components/Pages/CampusAmbassdor/CALogin/CALogin';
import SignUpForm from './components/Pages/SignUp/SignUp'
import CASignUpForm from './components/Pages/CampusAmbassdor/CASignUp/CASignUp';
import CADashboard from './components/Pages/CampusAmbassdor/CADasboard/CADashboard';
import CAVerify from './components/Pages/CampusAmbassdor/CAVerify/CAVerify';
import CATaskUserSide from './components/Pages/CampusAmbassdor/CATasks/CATasks';
import CAShowTasks from './components/Pages/CampusAmbassdor/CAPr/CATasksPr/CATasksPr';
import CATaskSubmitForm from './components/Pages/CampusAmbassdor/CAPr/CAInputPr/CAInputPr';
import CAFetchMap from './components/Pages/CampusAmbassdor/CAPr/CAMaps/CAMap';
import CAExtraPoints from './components/Pages/CampusAmbassdor/CAPr/CAExtraPr/CAExtraPr';
import CALeaderboard from './components/Pages/CampusAmbassdor/CALeaderboard/CALeaderboard';


import NewEventPage from './components/Pages/EventsConsolidated/NewEventPage.js';

import VideoScroll from './components/Pages/Home/Home.js';
// import Home from './components/Pages/Home/Home';
// import Threed from './components/Pages/Home/threed';
import Escalade from './components/Pages/Events/Robotics/Escalade/Escalade';
import Escaladeregister from './components/Pages/Events/Robotics/Escalade/Escaladeregister';

import Workshops from './components/Pages/EventsModule/Workshops/Workshop.js';
import Exhibitions from './components/Pages/EventsModule/Exhibitions/Exhibitions.js';
import Initiatives from './components/Pages/EventsModule/Initiatives/Initiatives.js';
import Competitions from './components/Pages/EventsModule/Competitions/Competitions.js';

import MicroFish from './components/Pages/Events/Robotics/MicroFish/MicroFish';

import UVDC from './components/Pages/Events/Robotics/UVDC/UVDC.js';

import Robowars from './components/Pages/Events/Robotics/Robowars/Robowars';
import Robowarsregister from './components/Pages/Events/Robotics/Robowars/Robowarsregister'
import RobowarsConfirmation from './components/Pages/Events/Robotics/Robowars/Robowarsconfirmation';

import TrackTitans from './components/Pages/Events/Robotics/Track Titans/TrackTitans';
import TrackTitansregister from './components/Pages/Events/Robotics/Track Titans/TrackTitansregister'
import TrackTitansConfirmation from './components/Pages/Events/Robotics/Track Titans/TrackTitansconfirmation';

import Aquawars from './components/Pages/Events/Robotics/Aquawars/Aquawars';
import Aquawarsregister from './components/Pages/Events/Robotics/Aquawars/Aquawarsregister';
import AquawarsConfirmation from './components/Pages/Events/Robotics/Aquawars/Aquawarsconfirmation';


import Glider from './components/Pages/Events/Techolympics/Glider/Glider.js';
import CaptureTheFlag from './components/Pages/Events/Techolympics/CaptureTheFlag/CaptureTheFlag.js';
import CodingHack from './components/Pages/Events/Techolympics/CodingHackathon/CodingHackathon.js';
import SmartCity from './components/Pages/Events/Techolympics/SmartCity/SmartCity.js';
import CircuitSimulation from './components/Pages/Events/Techolympics/CircuitSimulation/CircuitSimulation.js';

import Avinya from './components/Pages/Events/Corporate/Avinya/Avinya.js';
import CatalysisT from './components/Pages/Events/Corporate/Catalysis\'T/Catalysis\'T.js';

import TechExpo from './components/Pages/Events/TechExpo/TechExpo';
import TechexpoRegister from './components/Pages/Events/TechExpo/TechexpoRegister';

import Nexus from './components/Pages/Events/Nexus/Nexus.js';
import LectureSeries from './components/Pages/Events/LectureSeries/LectureSeries.js';

import ProjectShowcase from './components/Pages/Events/Exhibitions/ProjectShowcase/ProjectShowcase.js';
import AutoExpo from './components/Pages/Events/Exhibitions/AutoExpo/AutoExpo.js';

import Muskaan from './components/Pages/Events/Initiatives/Muskaan/Muskaan.js';
import WUV from './components/Pages/Events/Initiatives/WUV/WUV.js';
import Pragati from './components/Pages/Events/Initiatives/Pragati/Pragati.js';

import GroupPassForm from './components/Pages/Verification/Group Pass/GroupPass';
import ParticipantForm from './components/Pages/Verification/Participant/Participant';
import VehiclePassForm from './components/Pages/Verification/Vehicle Pass/VehiclePass';
import ViewersForm from './components/Pages/Verification/Viewers/Viewers';
import OthersForm from './components/Pages/Verification/Others/Others';
import QRScannerComponent from './components/Pages/Verification/qrscanner';


//GHM
import GhmMain from './components/Pages/Ghm/Ghm';
import GHMForm from './components/Pages/Ghm/GhmRegister/GhmRegister';
import EventGhm from './components/Pages/Ghm/components/Eventsghm';
import AboutGhm from './components/Pages/Ghm/components/newpage';


import { UserProvider } from './components/Pages/CampusAmbassdor/CALogin/UserContext';

import CoreTeamLogin from './components/Pages/CoreTeamPortal/CoreTeamLogin'
import EscaladeConfirmation from './components/Pages/Events/Robotics/Escalade/Escaladeconfirmation';
import FindUser from './components/Pages/CampusAmbassdor/CAPr/CAAllUsers/AllUsers';

import Data from "./components/Pages/Data/AllData";
import RoboData from "./components/Pages/Data/RoboData";
import TechExpoData from "./components/Pages/Data/TechExpoData";



const AppRoutes = () => {
  return (
    <UserProvider>
      <Routes>
        <Route path="/" element={<VideoScroll />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/events" element={<NewEventPage />} />
        <Route path="/merch" element={<Merch />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/signup" element={<SignUpForm />} />

        <Route path="/workshops" element={<Workshops />} />
        <Route path="/exhibitions" element={<Exhibitions />} />
        <Route path="/initiatives" element={<Initiatives />} />
        <Route path="/competitions" element={<Competitions />} />
        <Route path="/nexus" element={<Nexus />} />
        <Route path="/lectureseries" element={<LectureSeries />} />

        <Route path="/robotics/microfish" element={<MicroFish />} />
        <Route path="/robotics/uvdc" element={<UVDC />} />

        <Route path="/robotics/robowars" element={<Robowars />} />
        <Route path="/robotics/robowars/register" element={<Robowarsregister />} />
        <Route path="/robotics/robowars/confirm/:ciphertext" element={<RobowarsConfirmation />} />

        <Route path="/robotics/escalade" element={<Escalade />} />
        <Route path="/robotics/escalade/register" element={<Escaladeregister />} />
        <Route path="/robotics/escalade/confirm/:ciphertext" element={<EscaladeConfirmation />} />

        <Route path="/robotics/tracktitans" element={<TrackTitans />} />
        <Route path="/robotics/tracktitans/register" element={<TrackTitansregister />} />
        <Route path="/robotics/tracktitans/confirm/:ciphertext" element={<TrackTitansConfirmation />} />

        <Route path="/robotics/aquawars" element={<Aquawars />} />
        <Route path="/robotics/aquawars/register" element={<Aquawarsregister />} />
        <Route path="/robotics/aquawars/confirm/:ciphertext" element={<AquawarsConfirmation />} />

        <Route path="/techexpo" element={<TechExpo />} />
        <Route path="/techexpo/register" element={<TechexpoRegister />} />

        <Route path="/techolympics/glidercompetition" element={<Glider />} />
        {/* <Route path="/techolympics/capturetheflag" element={<CaptureTheFlag />} /> */}
        {/* <Route path="/techolympics/codinghackathon" element={<CodingHack />} /> */}
        <Route path="/techolympics/smartcityhackathon" element={<SmartCity />} />
        <Route path="/techolympics/circuitsimulation" element={<CircuitSimulation />} />

        <Route path="/corporate/avinya" element={<Avinya />} />
        <Route path="/corporate/catalysist" element={<CatalysisT />} />

        <Route path="exhibitions/projectshowcase" element={<ProjectShowcase />} />
        <Route path="exhibitions/autoexpo" element={<AutoExpo />} />

        <Route path="initiatives/muskaan" element={<Muskaan />} />
        <Route path="initiatives/pragati" element={<Pragati />} />
        <Route path="initiatives/wakeupandvote" element={<WUV />} />

        <Route path="/ghm" element={<GhmMain />} />
        <Route path="/ghm/register" element={<GHMForm />} />
        <Route path="/ghm/eventdetails" element={<EventGhm />} />
        <Route path="/ghm/about" element={<AboutGhm />} />



        <Route path="/ca" element={<CaHome />} />
        <Route path="/calogin" element={<CALoginForm />} />
        <Route path="/casignup" element={<CASignUpForm />} />
        <Route path="/cadashboard/:sanitizedHash" element={<CADashboard />} />
        <Route path="/caverify" element={<CAVerify />} />
        <Route path="/cadashboard/:sanitizedHash/viewtasks" element={<CATaskUserSide />} />
        <Route path="/cadashboard/:sanitizedHash/leaderboard" element={<CALeaderboard />} />
        <Route path='/caprkibakchodi567796!9905' element={<CATaskSubmitForm />} />
        <Route path='/caprkibakchodi567796!9905/alltasks' element={<CAShowTasks />} />
        <Route path='/caprkibakchodi567796!9905/submissions' element={<CAFetchMap />} />
        <Route path='/caprkibakchodi567796!9905/extrapoints' element={<CAExtraPoints />} />
        <Route path='/caprkibakchodi567796!9905/allusers' element={<FindUser />} />



        <Route path="/dashboard/:sanitizedHash" element={<Dashboard />} />
        <Route path="/dashboard/:sanitizedHash/rwaddteam" element={<AddTeamRW />} />
        <Route path="/dashboard/:sanitizedHash/rwviewteam" element={<ViewTeamDetailsRW />} />
        <Route path="/dashboard/:sanitizedHash/rwsubmission" element={<SubmissionRW />} />
        <Route path="/dashboard/:sanitizedHash/awaddteam" element={<AddTeamAW />} />
        <Route path="/dashboard/:sanitizedHash/awviewteam" element={<ViewTeamDetailsAW />} />
        <Route path="/dashboard/:sanitizedHash/awsubmission" element={<SubmissionAW />} />
        <Route path="/dashboard/:sanitizedHash/esaddteam" element={<AddTeamES />} />
        <Route path="/dashboard/:sanitizedHash/esviewteam" element={<ViewTeamDetailsES />} />
        <Route path="/dashboard/:sanitizedHash/essubmission" element={<SubmissionES />} />
        <Route path="/dashboard/:sanitizedHash/ttaddteam" element={<AddTeamTT />} />
        <Route path="/dashboard/:sanitizedHash/ttviewteam" element={<ViewTeamDetailsTT />} />
        <Route path="/dashboard/:sanitizedHash/ttsubmission" element={<SubmissionTT />} />
    


        {/* wtf is this.... SORT THIS BEFORE TECHNICHE */}
        <Route path="/grouppass" element={<GroupPassForm />} />
        <Route path="/participants" element={<ParticipantForm />} />
        <Route path="/vehicles" element={<VehiclePassForm />} />
        <Route path="/viewers" element={<ViewersForm />} />
        <Route path="/others" element={<OthersForm />} />
        <Route path="/qrscanner" element={<QRScannerComponent />} />

        <Route path="/2024coretechnicheteamcontrol" element={<CoreTeamLogin />} />
        <Route path="/get-reg-data/2024/lessgoo" element={<Data />} />
        <Route path="/get-reg-data/2024/robo" element={<RoboData />} />
        <Route path="/get-reg-data/2024/techexpo" element={<TechExpoData />} />
        {/* <Route path="/2024coretechnicheteamcontrol/roboticsdashboard/::sanitizedHash" element={<CoreTeamLogin />} /> */}
        {/* <Route path="/pass" element={</>} /> */}
      </Routes>
    </UserProvider>
  );
};

export default AppRoutes;